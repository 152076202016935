import {
  HeaderContainer,
  HeaderLogo,
  HeaderMenu,
  HeaderMenuItem,
  HeaderWrapper,
} from "./styled";
import ButtonCommon from "../Common/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { ContextProviderWrapper } from "../Context";
import { useLenis } from "@studio-freight/react-lenis";
import { ROUTER_PATHS } from "../../routers";
import {
  useTonAddress,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import ModalOverlay from "../Common/ModalOverlay";
import WalletInfo from "../Common/Wallet";
import { shortenAddress } from "../../utils/addressUser";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch } from "../../store/store";
import { removeToken } from "../../store/features/authSlice";

export default function Header() {
  const { pathname, hash } = useLocation();
  const [tonConnectUI] = useTonConnectUI();
  const wallet: any = useTonWallet();
  const address = useTonAddress();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useAuth();

  const { isMobile } = useContext(ContextProviderWrapper)!;
  const lenisInstance: any = useLenis();

  const [openModal, setOpenModal] = useState(false);
  const handleToSection = (e: any, sectionId: string) => {
    lenisInstance?.scrollTo(`${sectionId}`);
  };

  const handleConnectWallet = () => {
    if (pathname !== ROUTER_PATHS.BUY_ICO) {
      window.open("https://exchange.iton.co/", "_blank");
    } else if (wallet) {
      setOpenModal(true);
    } else {
      tonConnectUI.openModal();
    }
  };

  const removeTokenCookie = () => {
    const TokenCookieKey = process.env.REACT_APP_LOCAL_TOKEN_COOKIE_KEY ?? "";
    localStorage.removeItem("token");
    dispatch(removeToken());
    document.cookie = `${TokenCookieKey}=; max-age=0; Expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    if (tonConnectUI.connected) {
      tonConnectUI.disconnect();
    }
  };

  const handleDisconnect = () => {
    removeTokenCookie();
  };

  return (
    <HeaderContainer>
      <ModalOverlay
        component={
          <WalletInfo
            onDisConnect={handleDisconnect}
            onToSwap={() => {
              setOpenModal(false);
            }}
          />
        }
        onClickParent={() => {
          setOpenModal(false);
        }}
        open={openModal}
        setOpen={setOpenModal}
        title={"Your Wallet"}
        width="1000px"
        isSearch={false}
      />
      <HeaderWrapper>
        <HeaderLogo>
          <a
            href="#home"
            onClick={(e) => {
              if (pathname === ROUTER_PATHS.BUY_ICO) {
                navigate(ROUTER_PATHS.HOME);
              } else {
                handleToSection(e, "#home");
              }
            }}
          >
            <div className="icon-logo">
              <img src="/assets/images/landing/logo.webp?v=1" alt="logo" />
            </div>
          </a>
        </HeaderLogo>
        <HeaderMenu>
          {!isMobile &&
            pathname !== ROUTER_PATHS.BUY_ICO &&
            NAV_SECTIONS.map((item: any, index: number) => {
              return (
                <>
                  {!item?.isHiden && (
                    <a
                      href={item.link}
                      onClick={(e) => {
                        handleToSection(e, item.link);
                      }}
                    >
                      <HeaderMenuItem key={index}>
                        <span
                          className={
                            item.link === hash || (!hash && index === 0)
                              ? "active"
                              : ""
                          }
                        >
                          {item.label}
                        </span>
                      </HeaderMenuItem>
                    </a>
                  )}
                </>
              );
            })}
          <ButtonCommon
            icon="/assets/images/icon-arrow-right.webp?v=1"
            width={
              isMobile && pathname !== ROUTER_PATHS.BUY_ICO ? "170px" : "200px"
            }
            text={
              pathname !== ROUTER_PATHS.BUY_ICO
                ? "Launch App"
                : address
                ? `${shortenAddress(address)}`
                : "Connect Wallet"
            }
            onClick={() => {
              handleConnectWallet();
            }}
          />
        </HeaderMenu>
      </HeaderWrapper>
    </HeaderContainer>
  );
}

const NAV_SECTIONS = [
  {
    label: "Home",
    link: "#home",
  },
  {
    label: "Key Features",
    link: "#Features",
  },
  {
    label: "Benefit",
    link: "#Benefit",
  },
  // {
  //   label: "Team Member",
  //   link: "#Team",
  // },
  {
    label: "Roadmap",
    link: "#Roadmap",
  },
  {
    label: "Partners",
    link: "#Partners",
  },
];
