import { useContext, useEffect, useRef, useState } from "react";
import { formatUnitTime } from "../../utils/formatUnitTime";
import { TableTitle } from "../Liquidity/styled";
import {
  Balance,
  BtnSubmit,
  ItemICO,
  ListTotal,
  OptionWallet,
  OptionsWallet,
  Percents,
  RowInfo,
  RowSelectWallet,
  SelectWallet,
  TitleItem,
  TitleSelectToken,
  TokenSaled,
  TotalBalanceFt,
  TotalItem,
  WrapInfo,
  WrapSelectToken,
} from "./styled";
import { convertFixed } from "../../utils/convertFormatNumber";
import { toast } from "react-hot-toast";
import { instance } from "../../services/instance";
import { useDebouncedCallback } from "use-debounce";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { ContextProviderWrapper } from "../../components/Context";
import { Coins } from "ton3-core";

type ElementICOType = {
  itemICO: any;
  balanceTonWallet: any;
  balanceTonHolding: any;
  onBuyICO: any;
};

export default function ElementICO({
  itemICO,
  balanceTonWallet,
  balanceTonHolding,
  onBuyICO,
}: ElementICOType) {
  const [timeCountDown, setTimeCountDown] = useState<any>(null);
  const [isDisable, setIsDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowWallet, setIsShowWallet] = useState(false);
  const [balance, setBalance] = useState<any>(null);
  const [walletSelect, setWalletSelect] = useState({
    label: "Ton Wallet",
    value: "ton",
  });
  const [listWallet] = useState([
    {
      label: "Ton Holding Wallet",
      value: "ton-holding",
    },
    {
      label: "Ton Wallet",
      value: "ton",
    },
  ]);
  const [amount, setAmount] = useState<any>();
  const [valueUSDToTon, setValueUSDToTon] = useState(0);
  const [tokenRecevie, setTokenRecevie] = useState<any>(null);
  const [tonConnectUI] = useTonConnectUI();
  const address = useTonAddress();
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const refSelect: any = useRef();

  const countDownTime = (dateTime: number, type: string, minute?: any) => {
    // Set the date we're counting down to
    let currentDate = 0;
    let currentHours = 0;
    let currentMins = 0;
    let currentSecs = 0;

    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = dateTime - now;

      // Time calculations for days, hours, minutes and seconds
      currentDate =
        Math.floor(distance / (1000 * 60 * 60 * 24)) <= 0
          ? 0
          : Math.floor(distance / (1000 * 60 * 60 * 24));
      currentHours =
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      currentMins =
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      currentSecs =
        Math.floor((distance % (1000 * 60)) / 1000) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60)) / 1000);

      if (type === "start") {
        setTimeCountDown(
          `${formatUnitTime(currentDate)}:${formatUnitTime(
            currentHours
          )}:${formatUnitTime(currentMins)}:${formatUnitTime(currentSecs)}`
        );
      }

      if (distance <= 0 && type === "start") {
        clearInterval(x);
        setIsDisable(false);
        setTimeCountDown(null);
      }

      if (distance <= 0 && type === "end") {
        setIsDisable(true);
        clearInterval(x);
      }
    }, 1000);
  };

  const handleBuyICO = async () => {
    if (!address) {
      tonConnectUI.openModal();
    } else if (!amount || amount === 0 || isLoading || !balance) {
      return;
    } else {
      setIsLoading(true);
      const totalUSDCanBuy = itemICO?.total_raise - itemICO?.total_saled;
      if (amount <= totalUSDCanBuy && valueUSDToTon <= balance) {
        handleSendTx(amount);
      } else {
        const valueUSD = await onGetUSD();

        toast.error(
          `You Can Only Buy: $${Number(
            Number(balance ? balance : 0) * valueUSD
          )?.toFixed(2)}`
        );
        setIsLoading(false);
      }
    }
  };

  const handleSendTx = async (amount: number) => {
    try {
      const payload = {
        amount_usd: amount,
        on_chain: walletSelect.value === "ton",
      };
      const { data } = await instance.post(`/presale/order`, payload);

      if (
        walletSelect.value === "ton" &&
        data &&
        Number(Coins.fromNano(data.amount, 9)) < balance &&
        Number(Coins.fromNano(data.amount, 9)) > 0
      ) {
        const response = await tonConnectUI?.sendTransaction({
          validUntil: Math.floor(new Date().getTime() / 1000) + 60 * 10, // 10 minutes
          messages: [
            { address: data.to, amount: data.amount, payload: data.payload },
          ],
        });
        if (response.boc) {
          toast.success(
            "Send transaction completed,wait a few seconds to receive ITON!"
          );
        }
        setIsLoading(false);
      } else {
        toast.success("Transaction successfully");
        setIsLoading(false);
      }
      setIsLoading(false);
      handleGetTokenReceive(itemICO?.id);
      onBuyICO();
      setAmount(0);
      setBalance(balance - valueUSDToTon);
    } catch (error: any) {
      if (error?.error?.data?.detail) {
        toast.error(
          error?.error?.data?.detail?.message
            ? `${error?.error?.data?.detail?.message}`
            : error?.error?.data?.detail
            ? `${error?.error?.data?.detail}`
            : "Transaction Rejected"
        );
      } else {
        handleRejectTx();
      }
      setIsLoading(false);
    }
  };

  const handleRejectTx = async () => {
    try {
      await instance.get("/presale/cancle");
      toast.error("Transaction Rejected");
    } catch (error: any) {
      console.log("handleRejectTx err", error.error);
      toast.error(
        error?.error?.data?.detail?.message
          ? `${error?.error?.data?.detail?.message}`
          : error?.error?.data?.detail
          ? `${error?.error?.data?.detail}`
          : "Transaction Rejected"
      );
    }
  };

  const onGetUSD = async () => {
    try {
      const { data } = await instance.get(`/rates/ton-price`);
      return Number(data?.usd);
    } catch (error) {
      console.log("onGetUSD err", error);
      return 0;
    }
  };

  const handleGetPriceUSD = useDebouncedCallback(
    async (value) => {
      try {
        const valueUSD = await onGetUSD();
        setValueUSDToTon(Number(value) / valueUSD);
      } catch (error) {
        console.log("handleGetPriceUSD err", error);
      }
    },
    // delay in ms
    500
  );

  const handleSetBuyMax = async () => {
    try {
      if (!balance || balance === 0 || isDisable) return;
      const balanceAvailible = balance - balance * 0.005;
      const valueUSD = await onGetUSD();
      const valueAmount: any = Number(valueUSD * balanceAvailible);
      setValueUSDToTon(balanceAvailible);
      setAmount(valueAmount.toFixed(2));
    } catch (error) {
      console.log("handleSetBuyMax", error);
    }
  };

  const handleGetTokenReceive = async (id: any) => {
    try {
      if (!address || !id) return;
      const token = await localStorage.getItem("token");
      if (!token) return;
      const { data } = await instance.get(`/presale/balance?round_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTokenRecevie(data);
    } catch (error) {
      console.log("handleGetTokenReceive err", error);
    }
  };

  const onSelectWallet = (wallet: any) => {
    if (wallet.value === "ton-holding") {
      setBalance(balanceTonHolding);
    } else {
      setBalance(balanceTonWallet);
    }
    setWalletSelect(wallet);
  };

  const handleClickOutside = (e: any) => {
    if (!refSelect.current.contains(e.target)) {
      setIsShowWallet(false);
    }
  };

  const handleClickInside = () => {
    setIsShowWallet(true);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    if (itemICO && !itemICO?.start_date) return;
    countDownTime(itemICO?.start_date * 1000, "start");
    countDownTime(itemICO?.end_date * 1000, "end");
    handleGetTokenReceive(itemICO.id);
  }, [itemICO]);

  useEffect(() => {
    if (!address) {
      setTokenRecevie(null);
    }
  }, [address]);

  useEffect(() => {
    if (balanceTonWallet) {
      setBalance(balanceTonWallet);
    }
  }, [balanceTonWallet]);

  useEffect(() => {
    setAmount(0);
    setValueUSDToTon(0);
  }, [walletSelect]);

  return (
    <ItemICO id="out-side">
      <TitleItem className="row-between">
        <TableTitle>
          <div className="icon-header">
            <img src="/assets/images/BuyICO/im-title-ico.png" alt="title" />
          </div>
          <p className="title-light">{itemICO?.title?.split(" ")[0]}</p>
          <p className="title-bold">{itemICO?.title?.split(" ")[1]}</p>
        </TableTitle>
      </TitleItem>
      <WrapInfo isActive={!isDisable}>
        <ListTotal className="row-center">
          {itemICO?.totals?.map((total: any, jndex: number) => (
            <TotalItem className="row-left" key={jndex}>
              <div>
                <h2>{total.label}</h2>
                <div className="row-left">
                  <h1>{total.value}</h1>
                </div>
              </div>
            </TotalItem>
          ))}
        </ListTotal>
        <RowInfo className="row-between">
          <h1>Start Pool</h1>
          <h2>{itemICO?.timeStart}</h2>
        </RowInfo>
        <RowInfo className="row-between">
          <h1>End Pool</h1>
          <h2>{itemICO?.timeEnd}</h2>
        </RowInfo>
        <RowInfo className="row-between">
          <h1>Max Buy</h1>
          <h2>{itemICO?.maxBuy}</h2>
        </RowInfo>
        <TokenSaled className="row-between">
          <h1>Token Saled</h1>
          <div className="percent">
            <p>
              {itemICO?.total_saled
                ? Number(
                    (itemICO?.total_saled / itemICO?.total_raise) * 100
                  )?.toFixed(2)
                : 0}
              %
            </p>
          </div>
        </TokenSaled>
        <Percents
          percent={
            (itemICO?.total_saled / itemICO?.total_raise) * 100 > 100
              ? 100
              : (itemICO?.total_saled / itemICO?.total_raise) * 100
          }
        >
          <div className="row-center img-blur">
            <img src="/assets/images/BuyICO/img-blur.png" alt="" />
          </div>
          <h1>
            {itemICO?.total_saled > itemICO?.total_raise
              ? `${convertFixed(Number(itemICO?.total_raise))}`
              : itemICO?.tokenSended?.replaceAll("$", "")}{" "}
            <span>
              /
              {itemICO?.total_raise
                ? convertFixed(Number(itemICO?.total_raise))
                : 0}
            </span>
          </h1>
        </Percents>
        <WrapSelectToken>
          <TitleSelectToken>
            <RowSelectWallet
              style={{
                opacity: isDisable ? ".5" : 1,
                pointerEvents: isDisable ? "none" : "auto",
              }}
              className="row-between"
            >
              <div className="row-left">
                <div id="clickbox">
                  <SelectWallet onClick={handleClickInside} ref={refSelect}>
                    <h2>
                      {isMobile
                        ? `${walletSelect.label.slice(0, 10)}...`
                        : walletSelect.label}
                    </h2>
                    <div
                      style={{
                        transform: isShowWallet
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                      className="img-arrow"
                    >
                      <img
                        src="/assets/images/BuyICO/img-arrow-down.svg"
                        alt="images"
                      />
                    </div>
                  </SelectWallet>
                  <OptionsWallet
                    style={{
                      height: isShowWallet ? "auto" : 0,
                      opacity: isShowWallet ? "1" : 0,
                    }}
                  >
                    {listWallet.map((item: any, index: number) => (
                      <OptionWallet
                        key={index}
                        className={
                          isShowWallet
                            ? "animate__animated animate__faster animate__fadeInDown"
                            : "animate__animated animate__faster animate__fadeOutUp"
                        }
                        onClick={() => {
                          onSelectWallet(item);
                          setIsShowWallet(false);
                        }}
                      >
                        <h2>{item.label}</h2>
                      </OptionWallet>
                    ))}
                  </OptionsWallet>
                </div>
              </div>
              <div className="row-left">
                <h1>
                  Balance:{" "}
                  {balance && address ? Number(balance).toFixed(2) : "0.0"}
                </h1>
                <div className="img-title">
                  <img src="/assets/images/BuyICO/img-ton.png" alt="images" />
                </div>
              </div>
            </RowSelectWallet>
          </TitleSelectToken>
          <TitleSelectToken className="row-between">
            <div className="row-left">
              <h1>Enter Amount</h1>
            </div>
            <div className="row-left"></div>
          </TitleSelectToken>
          <Balance>
            <div className="row-left">
              <h1>$</h1>
              <input
                type="number"
                inputMode="decimal"
                placeholder="0.00"
                onChange={(e: any) => {
                  setValueUSDToTon(0);
                  setAmount(e.target.value);
                  handleGetPriceUSD(e.target.value);
                }}
                min={0}
                value={amount}
                autoFocus={isDisable}
                disabled={isDisable || balance === 0}
              />
            </div>
            <div className="row-between">
              <h2>~{valueUSDToTon ? valueUSDToTon?.toFixed(2) : 0} TON</h2>
              <p
                style={{
                  opacity: isDisable ? "0.5" : 1,
                  cursor: isDisable ? "not-allowed" : "pointer",
                  pointerEvents: isDisable ? "none" : "auto",
                }}
                onClick={handleSetBuyMax}
              >
                MAX
              </p>
            </div>
          </Balance>
        </WrapSelectToken>
      </WrapInfo>
      <BtnSubmit
        style={{
          opacity: isDisable ? "0.5" : 1,
          cursor: isDisable ? "not-allowed" : "pointer",
          pointerEvents: isDisable ? "none" : "auto",
        }}
        onClick={handleBuyICO}
      >
        <p>
          {timeCountDown
            ? `${timeCountDown}`
            : isLoading
            ? "Waiting..."
            : !isDisable && !address
            ? "Connect Wallet"
            : "Buy IDO"}
        </p>
        {tokenRecevie && tokenRecevie.total_token !== 0 && (
          <div className="img-line row-center">
            <img src="/assets/images/BuyICO/img-line.png" alt="" />
          </div>
        )}
      </BtnSubmit>
      {tokenRecevie && tokenRecevie.total_token !== 0 && address && (
        <>
          <TotalBalanceFt
            style={{
              opacity: itemICO?.can_claim ? "1" : "0.5",
            }}
            className="row-center"
          >
            <h1>Total iTON Will receive</h1>
            <div className="row-center">
              <h2>{convertFixed(Number(tokenRecevie.total_token))}</h2>
              <div className="img-icon row-center">
                <img src="/assets/images/landing/logo-iton.webp" alt="" />
              </div>
            </div>
          </TotalBalanceFt>
          <BtnSubmit
            style={{
              background: "#6b6b6b",
              opacity: itemICO?.can_claim ? "1" : "0.5",
              cursor: itemICO?.can_claim ? "pointer" : "not-allowed",
            }}
          >
            <div className="row-center">
              <p>{itemICO?.can_claim ? "Claim" : "Waiting..."}</p>
            </div>
          </BtnSubmit>
        </>
      )}
    </ItemICO>
  );
}
